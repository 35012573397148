import { Navigate, Outlet } from 'react-router-dom';

import { AuthConsumer, AuthProvider, INTERNAL_ROLES, CARRIER_ROLES } from '../../contexts/auth-context';
import { WebsocketProvider } from '../../contexts/websocket-context';
import { NotificationsProvider } from '../notification/pages/context';
import { CarrierPortalCarrierProvider } from './CarrierPortalCarrierContext';
import { CarrierPortalDashboard } from './CarrierPortalDashboard';

export const CarrierPortalWrapper = () => {
  return (
    <AuthProvider fallbackLogin="/carrier-portal/login">
      <AuthConsumer>
        {(auth) => {
          if (!auth?.me) {
            return <Navigate to="/carrier-portal/login" />;
          }

          if (INTERNAL_ROLES.includes(auth.me.role)) {
            return <Navigate to="/internal" />;
          }
          
          // TODO: Carrier id
          if (CARRIER_ROLES.includes(auth.me.role) && auth.me.carrier?.id) {
            return null;
            // return <Navigate to={`/customer-portal/${auth.me.customer.id}`} />;
          } else {
            return <Navigate to="/" />;
          }
        }}
      </AuthConsumer>

      <WebsocketProvider>
        <NotificationsProvider>
          <CarrierPortalCarrierProvider>
            <CarrierPortalDashboard>
              <Outlet />
            </CarrierPortalDashboard>
          </CarrierPortalCarrierProvider>
        </NotificationsProvider>
      </WebsocketProvider>
    </AuthProvider>
  );
};
