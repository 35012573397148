import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { MeQuery, useLogoutMutation, useMeQuery } from '../generated/graphql';
import createContext from './create-context';
import { useTranslation } from './translation-context';

export const INTERNAL_ROLES = ['InternalAdmin']
export const CARRIER_ROLES = ['CarrierDispatcher', 'CarrierDriver']

interface ProviderValue {
  me: NonNullable<MeQuery['me']>;
  logout: () => Promise<void>;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface AuthProviderProps {
  children?: React.ReactNode;
  fallbackLogin: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children, fallbackLogin } = props;
  const [{ data }] = useMeQuery({
    requestPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();
  const [_res, executeLogout] = useLogoutMutation();
  const { setLanguage } = useTranslation();

  const logout = React.useCallback(async () => {
    await executeLogout({});
    navigate(fallbackLogin);
  }, [executeLogout, navigate]);

  React.useEffect(() => {
    if (data?.me) {
      setLanguage(data.me.language);
    }
  }, [data]);

  const me = data?.me;
  if (!me) {
    return <Navigate to={fallbackLogin} />;
  } else {
    return <ReactProvider value={{ me, logout }}>{children}</ReactProvider>;
  }
};

export const useAuth = useContext;
export const AuthConsumer = ReactConsumer;
