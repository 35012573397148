import { useReducer } from 'react';
import dayjs from 'dayjs';
import { createId } from '@paralleldrive/cuid2';

import { OrderSupplierType, OrderSupplierTruckType, OrderType, SupplierType } from './CarrierPortalPlanningPage';
import { OrderStatus } from '../../../generated/graphql';

export interface IPlanningState {
  date: Date;
  supplierOrders: Map<string, OrderType[]>;
  supplier: SupplierType;
  pendingOrders: OrderType[];
  allOrders: OrderType[];
}

export type PlanningAction =
  | {
    type: 'supplier-fetched';
    supplier: SupplierType;
  }
  | {
    type: 'move-order';
    order: OrderType;
    supplier: OrderSupplierType | null;
    supplierTruck: OrderSupplierTruckType | null;
  }
  | {
    type: 'orders-fetched';
    orders: OrderType[];
  }
  | {
    type: 'sequence-changed';
    orderId: string;
    sequence: number;
    date: string;
  };

export function isTheSameDay(date1: Date, date2: Date) {
  return dayjs(date1).isSame(date2, 'day');
}

export function planningReducer(state: IPlanningState, action: PlanningAction) {
  switch (action.type) {
    case 'orders-fetched': {
      const orders = action.orders.filter((v) => v.status !== OrderStatus.Draft);

      return {
        ...state,
        pendingOrders: orders.filter((order) => !order.supplier),
        allOrders: orders,
      };
    }
    case 'move-order': {
      const foundOrder = state.allOrders.find((order) => order.id === action.order.id);
      if (!foundOrder) {
        return state;
      }

      const supplierOrdersMap = new Map(state.supplierOrders);

      if (foundOrder.supplierTruck) {
        const supplierOrders = supplierOrdersMap.get(foundOrder.supplierTruck.id);
        if (supplierOrders) {
          supplierOrdersMap.set(
            foundOrder.supplierTruck.id,
            supplierOrders.filter((order) => order.id !== action.order.id),
          );
        }
      }

      foundOrder.supplier = action.supplier;
      foundOrder.supplierTruck = action.supplierTruck;

      if (action.supplierTruck) {
        const supplierOrders = supplierOrdersMap.get(action.supplierTruck.id);
        if (supplierOrders) {
          supplierOrders.push(action.order);
          supplierOrdersMap.set(action.supplierTruck.id, [...supplierOrders]);
        }
      }

      return {
        ...state,
        supplierOrders: supplierOrdersMap,
        pendingOrders: state.allOrders.filter((order) => !order.supplier),
      };
    }
    case 'sequence-changed': {
      const orders = state.allOrders;
      const order = orders.find((v) => v.id === action.orderId);
      if (!order) {
        return state;
      }

      const existingSorting = order.routeSortings.find((v) => isTheSameDay(v.date, new Date(action.date)));
      if (existingSorting) {
        existingSorting.sequence = action.sequence;
      } else {
        order.routeSortings.push({
          id: createId(),
          date: new Date(action.date),
          sequence: action.sequence,
        });
      }

      return {
        ...state,
        pendingOrders: orders.filter((order) => !order.supplier),
        allOrders: orders,
      };
    }
    case 'supplier-fetched': {
      return {
        ...state,
        supplier: action.supplier,
      };
    }
  }

  return state;
}

export function usePlanningReducer(date: Date) {
  return useReducer(planningReducer, {
    date,
    supplierOrders: new Map(),
    supplier: null,
    pendingOrders: [],
    allOrders: [],
  });
}
