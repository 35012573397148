import { useState } from 'react';
import { DownloadSimple as DownloadIcon } from '@phosphor-icons/react';

import { Breadcrumb } from 'components/Breadcrumb';
import { LinkButton } from 'components/button/ButtonLink';
import { PageHeader } from 'components/PageHeader';
import { PageHeading } from 'components/PageHeading';
import { TimeFrame } from 'components/time-frame/time-frame';
import { TimeFrameState } from 'components/time-frame/types';
import { useTranslation } from 'contexts/translation-context';
import { useCustomerPortalSustainabilityReportingQuery } from 'generated/graphql';
import { Card } from 'components/Card';
import { formatNumber } from '@utils/number';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';

export const SustainabilityReportPage = () => {
  const { i18n } = useTranslation();
  const [timeFrame, setTimeFrame] = useState<null | TimeFrameState>(null);
  const { customer } = useCustomerPortalCustomer();
  const [{ data }] = useCustomerPortalSustainabilityReportingQuery({
    variables: {
      customerId: customer.id,
      filter: {
        startDate: timeFrame?.startDate,
        endDate: timeFrame?.endDate,
      },
    },
  });

  const results = data?.customerPortalSustainabilityReporting ?? {
    totalOrders: 0,
    totalDistance: 0,
    totalWeight: 0,
    totalEmissions: 0,
  };

  const divider = results.totalOrders || 1;
  const title = i18n('customerPortal.sustainability.sustainability');
  return (
    <div>
      <PageHeader title={title} />

      <PageHeading
        leftSide={<Breadcrumb currentItem={title} />}
        rightSide={
          <LinkButton
            to="https://storage.googleapis.com/transdirect-static-files/transdirect-esg-one-pager-v1.pdf"
            target="_blank"
          >
            {i18n('customerPortal.sustainability.policy')}
          </LinkButton>
        }
      />

      <div className="flex justify-between items-end px-8">
        <div>
          <LinkButton
            to={`/api/customer-portal/sustainability-report.pdf?customerId=${customer.id}&startDate=${timeFrame?.startDate}&endDate=${timeFrame?.endDate}`}
            target="_blank"
            iconLeft={<DownloadIcon className="w-4 h-4 mr-2" />}
          >
            Download
          </LinkButton>
        </div>

        <div>
          <TimeFrame onChange={setTimeFrame} />
        </div>
      </div>

      <div className="p-8">
        <div className="heading-three mb-4">{i18n('customerPortal.sustainability.total')}</div>

        <div className="grid gap-8 grid-cols-2 lg:grid-cols-4 mb-8">
          <Card title={i18n('customerPortal.sustainability.orderCount')}>{results.totalOrders}</Card>
          <Card title={i18n('customerPortal.sustainability.totalDistance')}>{`${formatNumber(
            results.totalDistance / 10,
            2,
            {
              decimalSeperator: ',',
            },
          )} km`}</Card>
          <Card title={i18n('customerPortal.sustainability.totalWeight')}>{`${formatNumber(
            results.totalWeight / 1000,
            2,
            {
              decimalSeperator: ',',
            },
          )} ton`}</Card>
          <Card title={i18n('customerPortal.sustainability.totalEmissions')}>{`${formatNumber(
            results.totalEmissions / 10,
            2,
            {
              decimalSeperator: ',',
            },
          )} kg co2eq`}</Card>
        </div>

        <div className="heading-three mb-4">{i18n('customerPortal.sustainability.avgPerOrder')}</div>

        <div className="grid gap-8 grid-cols-2 lg:grid-cols-4">
          <Card title={i18n('customerPortal.sustainability.totalDistance')}>{`${formatNumber(
            results.totalDistance / divider / 10,
            2,
            {
              decimalSeperator: ',',
            },
          )} km`}</Card>
          <Card title={i18n('customerPortal.sustainability.totalWeight')}>{`${formatNumber(
            results.totalWeight / divider,
            2,
            {
              decimalSeperator: ',',
            },
          )} kg`}</Card>
          <Card title={i18n('customerPortal.sustainability.totalEmissions')}>{`${formatNumber(
            results.totalEmissions / divider / 10,
            2,
            {
              decimalSeperator: ',',
            },
          )} kg co2eq`}</Card>
        </div>
      </div>
    </div>
  );
};
