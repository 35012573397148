import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { COUNTRY_VALUES } from '@utils/address';
import * as Yup from 'yup';
import { useResolvedPath } from 'react-router-dom';


import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { SimpleSelectField } from 'components/select/SimpleSelectField';
import { ITabItem, Tabs } from '../../../components/tabs/Tabs';
import { Country, Language, useCustomerPortalUpdateCustomerMutation } from 'generated/graphql';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { formatVatNumber } from '../../../utils/vat-number';
import { getDisplayError } from '../../../utils/get-display-error';
import { useCarrierPortalSupplier } from '../CarrierPortalCarrierContext';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTranslation } from '../../../contexts/translation-context';
import { DataField } from '../../../components/DataField';
import { PageHeading } from 'components/PageHeading';
import { FileUploaderButton } from '../../document/components/FileUploaderButton';
import { MultiFileUploaderButton } from '../../document/components/MultiFileUploaderButton';

// const updateCustomerSchema = Yup.object().shape({
//   street: Yup.string().min(1, 'errors.required').required('errors.required'),
//   streetNumber: Yup.string(),
//   city: Yup.string().min(1, 'errors.required').required('errors.required'),
//   postalCode: Yup.string().min(1, 'errors.required').required('errors.required'),
//   country: Yup.mixed().nullable().required('errors.required'),
//   language: Yup.mixed().nullable().required('errors.required'),
// });

export const CarrierPortalInfoWrapper = () => {
  const { supplier } = useCarrierPortalSupplier();
  const { i18n } = useTranslation();
  // const [, updateCustomerMutation] = useCustomerPortalUpdateCustomerMutation();

  const generalRoute = useResolvedPath('info');
  const contactsRoute = useResolvedPath('contacts');
  const trucksRoute = useResolvedPath('trucks');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: 'Wagenpark',
        path: trucksRoute.pathname,
      },
      {
        title: 'Contacten',
        path: contactsRoute.pathname,
      },
    ];

    return res;
  }, []);
  const initialValues = useMemo(() => {
    return {
      street: supplier.street,
      streetNumber: supplier.streetNumber ?? '',
      city: supplier.city,
      postalCode: supplier.postalCode,
      country: COUNTRY_VALUES.find((v) => v.key === supplier.country)!,
      language: LANGUAGE_VALUES.find((v) => v.key === supplier.language)!,
    };
  }, [supplier]);

  return (
    <div>
      <PageHeader title={i18n('customerPortal.businessInformation.updateBusinessInformation')} />

      <PageHeading
        leftSide={<Breadcrumb currentItem={i18n('customerPortal.businessInformation.businessInformation')} />}
      />
      <div className="px-4">

        <div className="flex gap-2 pb-4 self-end">
          <FileUploaderButton
            buttonText="BA Verzekering"
            title="BA Verzekering"
            initialName={`BA Verzekering - ${supplier.id}`}
            // file={
            //   // supplier.documents.find((d) => d.type === SupplierDocumentType.CivilLiabilityInsurance)?.document
            // }
            onSubmit={async (document) => {
              // const result = await linkDocument({
              //   supplierId: supplier.id,
              //   documentId: document.id,
              //   type: SupplierDocumentType.CivilLiabilityInsurance,
              // });
              // if (result.error) {
              //   throw result.error;
              // }
              toast.success('AB Verzekering opgeladen');
            }}
          />

          <FileUploaderButton
            buttonText="CMR Verzekering"
            title="CMR Verzekering"
            initialName={'CMR Verzekering'}
            // file={supplier.documents.find((d) => d.type === SupplierDocumentType.CmrInsurance)?.document}
            onSubmit={async (document) => {
              // const result = await linkDocument({
              //   supplierId: supplier.id,
              //   documentId: document.id,
              //   type: SupplierDocumentType.CmrInsurance,
              // });
              // if (result.error) {
              //   throw result.error;
              // }
            }}
          />

          <FileUploaderButton
            buttonText="Enquete"
            title="Enquete"
            initialName={'Enquete'}
            // file={supplier.documents.find((d) => d.type === SupplierDocumentType.Survey)?.document}
            onSubmit={async (document) => {
              // const result = await linkDocument({
              //   supplierId: supplier.id,
              //   documentId: document.id,
              //   type: SupplierDocumentType.Survey,
              // });
              // if (result.error) {
              //   throw result.error;
              // }
            }}
          />

          <FileUploaderButton
            buttonText="Transport vergunning"
            title="Transport vergunning"
            initialName="Transport vergunning"
            // file={supplier.documents.find((d) => d.type === SupplierDocumentType.TransportInsurance)?.document}
            onSubmit={async (document) => {
              // const result = await linkDocument({
              //   supplierId: supplier.id,
              //   documentId: document.id,
              //   type: SupplierDocumentType.TransportInsurance,
              // });
              // if (result.error) {
              //   throw result.error;
              // }
            }}
          />

          <MultiFileUploaderButton
            buttonText="Andere"
            title="Andere"
            files={[]}
            // files={supplier.documents.filter((d) => d.type === SupplierDocumentType.Other).map((v) => v.document)}
            onSubmit={async (documents) => {
              let count = 0;
              await Promise.allSettled(
                documents.map(async (doc) => {
                  try {
                    // const result = await linkDocument({
                    //   supplierId: supplier.id,
                    //   documentId: doc.id,
                    //   type: SupplierDocumentType.Other,
                    // });
                    // if (result.error) {
                    //   throw result.error;
                    // }
                    count += 1;
                    toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                  } catch (err) {
                    toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                  }
                }),
              );
            }}
            unlinkDocument={async (docId: string) => {
              try {
                // const result = await unlinkDocument({
                //   supplierId: supplier.id,
                //   documentId: docId,
                //   type: SupplierDocumentType.Other,
                // });
                // if (result.error) {
                //   throw result.error;
                // }
                toast.success('Bestand gewist');
              } catch (err) {
                toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
              }
            }}
          />
        </div>

        <Tabs items={tabItems} />

      </div>
    </div>
  );
};
