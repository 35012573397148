import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';
import { MagnifyingGlass } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetSupplierSelfBillsBySupplierDocument,
  GetSupplierSelfBillsBySupplierQuery,
  GetSupplierSelfBillsBySupplierQueryVariables,
  useGenerateSelfBillingsMutation,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Input } from '../../../components/input/Input';
import { ConfirmDialog } from 'components/dialog/ConfirmDialog';
import { getDisplayError } from '@utils/get-display-error';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';
import { useDebouncedSearchParams } from 'src/hooks/useDebouncedSearchParams';
import { FileUploaderButton } from '../../document/components/FileUploaderButton';


const SELF_BILL_HEADERS: ITableHeader[] = [
  {
    id: 'entryNumber',
    name: 'ID',
  },
  {
    id: 'createdAt',
    name: 'Datum',
  },
  {
    id: 'download',
    name: 'Download',
  },
];



const CarrierPortalSelfBillingsPage = () => {
  const [searchParams, setSearchParams] = useDebouncedSearchParams();
  const [searchValue, _setSearchValue] = useState(() => searchParams['search'] ?? '');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetSupplierSelfBillsBySupplierQuery, GetSupplierSelfBillsBySupplierQueryVariables>(
          GetSupplierSelfBillsBySupplierDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }
      console.log(result.data)
      return result.data?.supplierSelfBillsBySupplier ?? [];
    },
    [client, searchValue],
  );
  const [, generateSelfBillings] = useGenerateSelfBillingsMutation();

  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const setSearchValue = useCallback(
    (newValue: string) => {
      setSearchParams({ search: newValue });
      _setSearchValue(newValue);
    },
    [_setSearchValue],
  );

  const title = 'Self Billing';

  return (
    <>
      <PageHeader title={title} />

      <PageHeading
        leftSide={
          <Breadcrumb
            currentItem={title}
          />
        }
      />

      <div className="flex w-full px-4 mb-8">
        <Input
          type="text"
          placeholder="Zoek een self billing..."
          value={searchValue}
          onChange={setSearchValue}
          iconLeft={<MagnifyingGlass className="input-icon" />}
        />
      </div>

      <Table
        idKey="id"
        headers={SELF_BILL_HEADERS}
        data={page.data}
        mapData={(selfBill) => {
          return [
            selfBill.entryNumber
            ,
            formatDate(selfBill.createdAt),
            <FileUploaderButton
              buttonText="Factuur"
              title="Self Billing Invoice"
              file={selfBill.document}
              disableUpload
            />
          ];
        }}
      />

      <div className="my-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default CarrierPortalSelfBillingsPage;
