import createContext from 'contexts/create-context';
import * as React from 'react';

import { GetCarrierPortalSupplierByIdQuery, useGetCarrierPortalSupplierByIdQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { nullthrows } from '../../utils/invariant';

export type Supplier = NonNullable<GetCarrierPortalSupplierByIdQuery['supplier']>;

//TODO: Carrier Data Model
interface ProviderValue {
  supplier: Supplier;
  refreshData: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ICarrierPortalCarrierProviderProps {
  children?: React.ReactNode;
}

export const CarrierPortalCarrierProvider: React.FC<ICarrierPortalCarrierProviderProps> = (props) => {
  const { children } = props;
  const { carrierId: _supplierId } = useParams<{ carrierId: string }>();
  const supplierId = nullthrows(_supplierId, 'supplier id not defined');

  const [{ data }, _refreshData] = useGetCarrierPortalSupplierByIdQuery({
    variables: {
      id: parseInt(supplierId, 10),
    },
  });

  const refreshData = React.useCallback(() => {
    _refreshData({
      requestPolicy: 'network-only',
    });
  }, [_refreshData]);

  const supplier = nullthrows(data?.supplier, 'supplier not found');
  return <ReactProvider value={{supplier, refreshData }}>{children}</ReactProvider>;
};

export const useCarrierPortalSupplier = useContext;
export const Consumer = ReactConsumer;
