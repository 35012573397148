import { UserRole } from 'generated/graphql';

export function roleToNumber(role: UserRole | 'Anonymous'): number {
  switch (role) {
    case UserRole.InternalAdmin: {
      return 10;
    }
    case UserRole.InternalAdminPlus: {
      return 20;
    }
    case UserRole.InternalEmployeePlus: {
      return 5;
    }
    case UserRole.InternalEmployeePlanning: {
      return 1;
    }
    case 'Anonymous': {
      return 0;
    }
    default: {
      return 0;
    }
  }
}
