import React, { useEffect, useRef, useState } from 'react';
import { Clock, Calendar } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import { PageHeader } from '../../../components/PageHeader';
import { formatDate, formatInputDate, formatInputTime } from '../../../utils/date';
import { Input } from '../../../components/input/Input';
import {
  GetSupplierByIdQuery,
  GetOrdersQuery,
  SortDirection,
  useGetOrdersQuery,
  useGetSupplierByIdQuery,
  OrderDateFilterType,
  OrderLineStopType,
  useUpdateOrderMutation,
} from '../../../generated/graphql';
import { usePlanningReducer } from './reducer';
import { ORDER_STATUS_COLOR, ORDER_STATUS_OPTIONS } from '../../order/constants';
import { StatusBadge } from '../../../components/StatusBadge';
import { formatNumber, parseNumberInput } from '../../../utils/number';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { SpinnerBlock } from '../../../components/Spinner';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { InternalUser, UserCombobox } from '../../user/components/UserCombobox';
import { useAuth } from '../../../contexts/auth-context';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { Select } from 'components/select/Select';

export type SupplierType = GetSupplierByIdQuery['supplier'];
export type SupplierTruckType = SupplierType['trucks'][0];
export type OrderType = GetOrdersQuery['orders'][0];
export type OrderSupplierType = NonNullable<OrderType['supplier']>;
export type OrderSupplierTruckType = NonNullable<OrderType['supplierTruck']>;

type OnDropFn = (
  order: OrderType,
  supplier: OrderSupplierType | null,
  previousSupplier: OrderSupplierType | null,
  supplierTruck: OrderSupplierTruckType | null,
  previousSupplierTruck: OrderSupplierTruckType | null,
) => void;

export interface IOrderProps {
  index: number;
  date: string;
  order: OrderType;
  useMinimalLayout?: boolean;
}

export interface SupplierDropResult {
  type: 'supplier';
  supplier: SupplierType;
  truck: SupplierTruckType;
}

export type DropResult = SupplierDropResult | null;

export interface DragItem {
  order: OrderType;
  index: number;
}

const Order: React.FC<IOrderProps> = (props) => {
  const { order } = props;

  const linePurchases = order.lines.map((l) => l.purchases).flat();
  const purchaseTotal = calculateLinesTotalExclVat(linePurchases);

  return (
    <div className="rounded bg-dark-05 p-2">
      <div className="flex justify-between">
        <div className="font-medium">{order.orderNumber || 'DRAFT'}</div>
        <div className="whitespace-nowrap">{`AK € ${formatNumber(purchaseTotal, 2, {
          decimalSeperator: ',',
        })}`}</div>
        <StatusBadge color={ORDER_STATUS_COLOR[order.status]}>
          {ORDER_STATUS_OPTIONS.find((v) => v.key === order.status)?.name ?? order.status}
        </StatusBadge>
      </div>

      <div>Referentie klant: {order.customerRef}</div>

      <div>
        {order.lines.map((l) => {
          return (
            <div key={l.id}>
              {l.stops
                .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                .map((s) => {
                  return (
                    <div key={s.id}>
                      <div className="font-medium">{s.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}</div>
                      <div className="col-span-2">{`${s.location.name} - ${s.location.country} ${s.location.city}`}</div>
                      <div className="grid grid-cols-3">
                        <div className="font-regular">Ref: {s.reference}</div>
                        <div className="flex gap-4 col-span-2">
                          <div className="flex gap-1 items-center">
                            <Calendar className="button-icon" /> {formatDate(s.date)}
                          </div>
                          <div className="flex gap-1 items-center">
                            <Clock className="button-icon" />
                            {`${formatInputTime(s.timeStart)}-${formatInputTime(s.timeEnd)}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}

        <div className="flex flex-wrap gap-4 mt-2">
          {order.allowedTrailerTypes.map((v) => {
            return <TrailerTypeIcon key={`${order.id}-${v}`} type={v} variant="small" />;
          })}
        </div>
      </div>
    </div>
  );
};

export interface IPlanningContainerProps {
  date: string;
  supplier: SupplierType;
  truck: SupplierTruckType,
}

const CarrierPlanningContainer: React.FC<IPlanningContainerProps> = (props) => {
  const { date, supplier, truck } = props;

  const [loadOrdersResult, refetchLoadOrders] = useGetOrdersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      take: 500,
      orderBy: {
        orderNumber: SortDirection.Asc,
      },
      filters: {
        dateType: OrderDateFilterType.Load,
        startDate: date,
        endDate: date,
        supplierId: supplier?.id,
        supplierTruckId: truck?.id,
      },
    },
  });
  const [unloadOrdersResult, refetchUnloadOrders] = useGetOrdersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      take: 500,
      orderBy: {
        orderNumber: SortDirection.Asc,
      },
      filters: {
        dateType: OrderDateFilterType.Unload,
        startDate: date,
        endDate: date,
        supplierId: supplier?.id,
        supplierTruckId: truck?.id,
      },
    },
  });

  const [_updateOrderState, updateOrder] = useUpdateOrderMutation();

  const [state, dispatch] = usePlanningReducer(new Date(date));

  useEffect(() => {
    dispatch({
      type: 'supplier-fetched',
      supplier: supplier || [],
    });
  }, [supplier]);

  useEffect(() => {
    const _orders = [...(loadOrdersResult.data?.orders || []), ...(unloadOrdersResult.data?.orders || [])];
    const foundOrderIds = new Set<string>();
    const orders = _orders.filter((o) => {
      if (foundOrderIds.has(o.id)) {
        return false;
      } else {
        foundOrderIds.add(o.id);
        return true;
      }
    });

    dispatch({
      type: 'orders-fetched',
      orders,
    });
  }, [loadOrdersResult.data?.orders, unloadOrdersResult.data?.orders]);

  // TODO fix state.supplierOrders

  return (
    <div className="my-6 gap-4" style={{ gridTemplateColumns: '3fr 5fr' }}>
      <h2>Orders voor truck met nummerplaat {truck.licensePlate}</h2>

      <div>
        {state.allOrders.map((v) => {
          return (
            <Order
              key={v.id}
              index={0}
              date={date}
              order={v}
            />
          );
        })
        }
      </div>
    </div>
  );
};

export const CarrierPortalPlanningPage = () => {
  const { me } = useAuth();
  const [date, setDate] = useState(() => formatInputDate(new Date()));
  const [selectedTruck, setSelectedTruck] = useState<SupplierTruckType | null>(null);

  // Get supplier
  const [supplierResult] = useGetSupplierByIdQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      id: me.carrier?.id || -1,
    }
  });
  const supplier = supplierResult.data?.supplier;
  const trucks = supplier?.trucks || [];

  if (selectedTruck == null) {
    setSelectedTruck(trucks[0]);
  }

  return (
    <>
      <PageHeader title="Planning" />

      <PageHeading leftSide={<Breadcrumb currentItem="Planning" />} />

      <div className="px-4">
        <div className="flex gap-4">
          <label className="block max-w-xs">
            <div className="label-text py-1 w-full">Datum</div>
            <div>
              <Input type="date" value={date} onChange={setDate} />
            </div>
          </label>
          <label className="block max-w-xs">
            <div className="label-text py-1 w-full">Truck</div>
            <div>
              <Select items={trucks} keyName='licensePlate' selectedItem={selectedTruck} onSelect={setSelectedTruck} display={r => r.licensePlate} />
            </div>
          </label>
        </div>

        <React.Suspense fallback={<SpinnerBlock message="Orders aan het laden..." />}>
          <CarrierPlanningContainer date={date} key={`${formatDate(date)}`} supplier={supplier} truck={selectedTruck} />
        </React.Suspense>
      </div>
    </>
  );
};
