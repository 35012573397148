import React from 'react';
import { Link } from 'react-router-dom';

import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from '../../components/Breadcrumb';
import { PageHeader } from '../../components/PageHeader';
import { useTranslation } from '../../contexts/translation-context';
import { useCarrierPortalSupplier } from './CarrierPortalCarrierContext';
import { useAuth } from 'contexts/auth-context';
import { Money, Truck, Receipt, GasPump, Gear, Info, UserRectangle } from '@phosphor-icons/react';

export interface IHomeCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  to: string;
}

const HomeCard: React.FC<IHomeCardProps> = (props) => {
  const { icon, title, description, to } = props;

  return (
    <Link to={to} className="card p-16">
      <div className="text-dark-03 mb-2 flex justify-center">{icon}</div>
      <div className="font-medium text-dark-02 text-center border-b border-dark-05 pb-4">{title}</div>
      <div className="pt-4 text-center whitespace-normal">{description}</div>
    </Link>
  );
};

export const CarrierPortalHomePage = () => {
  const { me } = useAuth();
  const { supplier } = useCarrierPortalSupplier();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader
        title={i18n('carrierPortal.home.title', {
          customerName: supplier.name,
        })}
      />

      <PageHeading leftSide={<Breadcrumb currentItem={i18n('carrierPortal.home.carrierPortal')} />} />

      <div className="px-8 mb-8">
        <h1 className="flex gap-1 heading-one mb-4">
          <span>{i18n('carrierPortal.home.hello')}</span>
          <span className="text-orange-00">{`${me.name}!`}</span>
        </h1>

        <p>{i18n('carrierPortal.home.welcome')}</p>
      </div>

      <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4 px-8">
        <HomeCard
          to={`/carrier-portal/${supplier.id}/planning`}
          title={i18n('carrierPortal.planning.planning')}
          icon={<Receipt className="w-8 h-8" />}
          description={i18n('carrierPortal.planning.managePlanning')}
        />
        <HomeCard
          to={`/carrier-portal/${supplier.id}/orders`}
          title={i18n('carrierPortal.orders.orders')}
          icon={<Truck className="w-8 h-8" />}
          description={i18n('carrierPortal.orders.manageOrders')}
        />
        <HomeCard
          to={`/carrier-portal/${supplier.id}/self-billing`}
          title={i18n('carrierPortal.selfBilling.selfBilling')}
          icon={<Money className="w-8 h-8" />}
          description={i18n('carrierPortal.selfBilling.manageSelfBilling')}
        />
  
        <HomeCard
          to={`/carrier-portal/${supplier.id}/not-found`}
          title={i18n('carrierPortal.home.info')}
          icon={<Info className="w-8 h-8" />}
          description={i18n('carrierPortal.home.infoSubtitle')}
        />
        <HomeCard
          to={`/carrier-portal/${supplier.id}/not-found`}
          title={i18n('carrierPortal.home.contact')}
          icon={<UserRectangle className="w-8 h-8" />}
          description={i18n('carrierPortal.home.contactSubtitle')}
        />
        {/* <HomeCard
          to={`/carrier-portal/${supplier.id}/`}
          title={i18n('carrierPortal.home.FuelCard')}
          icon={<GasPump className="w-8 h-8" />}
          description={i18n('carrierPortal.home.FuelCardSubtitle')}
        /> */}
        <HomeCard
          to={`/carrier-portal/${supplier.id}/business-information`}
          title={i18n('carrierPortal.businessInformation.businessInformation')}
          icon={<Gear className="w-8 h-8" />}
          description={i18n('carrierPortal.businessInformation.settingsAndBusinessInformation')}
        />
      </div>
    </div>
  );
};
