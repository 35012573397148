import React from 'react';

import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from '../../components/Breadcrumb';
import { PageHeader } from '../../components/PageHeader';
import { useTranslation } from '../../contexts/translation-context';
import { useAuth } from 'contexts/auth-context';

export const CarrierPortalStubPage = () => {
  const { me } = useAuth();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader
        title={i18n('carrierPortal.notFound.pageNotFound')}
      />

      <PageHeading leftSide={<Breadcrumb currentItem={i18n('carrierPortal.notFound.pageNotFound')}/>}/>

      <div className="px-8 mb-8">
        <p>{i18n('carrierPortal.notFound.pageNotFoundDesc')}</p>
      </div>
    </div>
  );
};
