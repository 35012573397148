import { ArrowClockwise } from '@phosphor-icons/react';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { DataField } from '../../../../components/DataField';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { InputDialog } from '../../../../components/dialog/InputDialog';
import { PageHeader } from '../../../../components/PageHeader';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import {
  GetLocationByIdQuery,
  LocationDocumentType,
  UserRole,
  useGetLocationByIdQuery,
  useLinkLocationDocumentMutation,
  useUnlinkLocationDocumentMutation,
  useUpdateLocationMutation,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { MultiFileUploaderButton } from '../../../document/components/MultiFileUploaderButton';
import { LocationProvider } from './locationContext';
import { useAuth } from '../../../../contexts/auth-context';
import { PageHeading } from 'components/PageHeading';
import { captureException } from '@sentry/react';
import { roleToNumber } from 'src/app/auth/roles.client';

export type Location = NonNullable<GetLocationByIdQuery['location']>;

const LocationPage = () => {
  const { me } = useAuth();
  const navigate = useNavigate();
  const [, linkDocument] = useLinkLocationDocumentMutation();
  const [, unlinkDocument] = useUnlinkLocationDocumentMutation();
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId);
  const [{ data, error }, reexecuteQuery] = useGetLocationByIdQuery({
    variables: {
      id: +locationId,
    },
  });
  const [, updateLocation] = useUpdateLocationMutation();

  const generalRoute = useResolvedPath('general');
  const openingHoursRoute = useResolvedPath('opening-hours');
  const contactsRoute = useResolvedPath('contacts');
  const auditLogsRoute = useResolvedPath('audit-logs');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: 'Openingsuren',
        path: openingHoursRoute.pathname,
      },
      {
        title: 'Contacten',
        path: contactsRoute.pathname,
      },
    ];

    if (roleToNumber(me.role) >= roleToNumber(UserRole.InternalAdmin)) {
      res.push({
        title: 'Audit logs',
        path: auditLogsRoute.pathname,
      });
    }

    return res;
  }, []);

  const refetchData = useCallback(() => {
    return reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const location = data?.location;
  if (!location) {
    if (error) {
      return <div>Kon locatie niet laden: {error.message}</div>;
    } else {
      return <div>Locatie niet gevonden</div>;
    }
  } else {
    return (
      <>
        <LocationProvider location={location} refreshData={refetchData}>
          <PageHeader title={location.name} />

          <PageHeading
            leftSide={
              <Breadcrumb
                parentItem={{
                  name: 'Locaties',
                  to: '/internal/locations',
                }}
                currentItem={location.name}
              />
            }
            rightSide={
              <div className="flex gap-2">
                {location.deactivationReason ? (
                  <ConfirmDialog
                    triggerText="Heractiveer locatie"
                    title="Heractiveer locatie"
                    submitText="Heractiveer"
                    description="Ben je zeker dat je de locatie wilt heractiveren?"
                    onSubmit={async () => {
                      try {
                        const result = await updateLocation({
                          id: location.id,
                          data: {
                            deactivationReason: null,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Locatie is geheractiveerd');
                      } catch (err) {
                        captureException(err);
                        toast.error('Locatie heractiveren mislukt: ' + getDisplayError(err));
                      }
                    }}
                  />
                ) : (
                  <InputDialog
                    triggerText="Deactiveer locatie"
                    title="Deactiveer locatie"
                    submitText="Deactiveer"
                    description="Ben je zeker dat je de locatie wilt deactiveren?"
                    labelText="Reden"
                    validation={Yup.string().min(5, 'Minimum 5 karakters')}
                    onSubmit={async (value: string) => {
                      try {
                        const result = await updateLocation({
                          id: location.id,
                          data: {
                            deactivationReason: value,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Locatie is gedeactiveerd');
                        navigate('..');
                      } catch (err) {
                        captureException(err);
                        toast.error('Locatie deactiveren mislukt: ' + getDisplayError(err));
                        throw err;
                      }
                    }}
                  />
                )}

                <MultiFileUploaderButton
                  buttonText="Andere"
                  title="Andere"
                  files={location.documents.filter((d) => d.type === LocationDocumentType.Other).map((v) => v.document)}
                  onSubmit={async (documents) => {
                    let count = 0;
                    await Promise.allSettled(
                      documents.map(async (doc) => {
                        try {
                          const result = await linkDocument({
                            locationId: location.id,
                            documentId: doc.id,
                            type: LocationDocumentType.Other,
                          });
                          if (result.error) {
                            throw result.error;
                          }
                          count += 1;
                          toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                        } catch (err) {
                          captureException(err);
                          toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                        }
                      }),
                    );
                  }}
                  unlinkDocument={async (docId: string) => {
                    try {
                      const result = await unlinkDocument({
                        locationId: location.id,
                        documentId: docId,
                        type: LocationDocumentType.Other,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success('Bestand gewist');
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                    }
                  }}
                />

                <Button color="default" onTrigger={refetchData}>
                  <ArrowClockwise className="button-icon" />
                </Button>
              </div>
            }
          />

          <div className="px-4">
            <div className="flex mb-6">
              {!!location.customer && (
                <DataField title="Klant">
                  <Link to={`/internal/customers/${location.customer.id}/general`}>{location.customer.name}</Link>
                </DataField>
              )}
              {!!location.deactivationReason && (
                <DataField title="Deactivatie reden">{location.deactivationReason}</DataField>
              )}
            </div>

            <div className="mt-2">
              <Tabs items={tabItems} />
            </div>
          </div>
        </LocationProvider>
      </>
    );
  }
};

export default LocationPage;
