// Never import any server-only code here, this is also used on the client side...
import type { TranslationsMap } from './types';

// French version of the translation file
export const translations: TranslationsMap = {
  emails: {
    footer: {
      kindRegards: 'Cordialement,',
      questionsOrProblems: 'Des questions ou des problèmes?',
      contactUs: 'Contactez-nous',
    },
    transportOrder: {
      confirm: 'Confirmer',
      refuse: 'Refuser',
    },
    forgotPassword: {
      resetLink: 'Réinitialiser le mot de passe',
    },
    userInvite: {
      view: `Afficher l'invitation`,
    },
    attachments: {
      quotation: 'Offerte {{reference}}',
    },
    customerComplaintAlert: {
      order: 'Voir la commande',
    },
  },
  truckDocumentType: {
    Insurance: 'Assurance',
    Examination: 'Examen',
  },
  trailerTypes: {
    BoxTrailer: 'Remorque fourgon',
    FlatBed: 'Plateau',
    Mega: 'Méga-remorque',
    Refrigerated: 'Remorque réfrigérée',
    RoadTrain: 'Train routier',
    Tautliner: 'Rideau coulissant',
    Joloda: 'Rideau coulissant / Joloda',
  },
  documents: {
    note: {
      extraInformation: 'Informations supplémentaires',
    },
    letter: {
      letter: 'Lettre',
    },
    paymentReminder: {
      invoiceNumber: 'Numéro de facture',
      invoiceDate: 'Date de facturation',
      dueDate: "Date d'échéance",
      amount: 'Montant',
      expiredAmount: 'Montant expiré',
      currency: 'Devise',
    },
    quotation: {
      quotation: 'Offerte',
      customerNumber: 'Numéro de client',
      reference: 'Référence',
      date: "Date de l'offre",
      dueDate: "Date d'échéance",
      from: 'De',
      to: 'À',
      volume: 'Volume',
      price: 'Prix',
      generalTermsAndConditions: 'Conditions générales de vente jointes',
      questionsOrComments: "Pour toute question, veuillez contacter nous à l'adresse {{email}}",
      trailerTypes: 'Types de remorques',
    },
    transportOrder: {
      trailerTypes: 'Types de remorques',
      transportOrder: 'Ordre de transport',
      date: 'Date',
      orderRef: 'Référence de la commande',
      load: 'Charger',
      unload: 'Décharger',
      reference: 'Référence',
      goods: 'Marchandises',
      description: 'Description',
      packageType: 'Type de palette',
      palletCount: 'Palettes',
      weight: 'Poids',
      LoadingMeter: 'Compteur de charge',
      freightRate: 'Tarif de fret',
      price: 'Prix',
      vat: 'TVA',
      questionsOrComments: 'Questions ou commentaires?',
      contactFileManager: `N'hésitez pas à contacter votre responsable de dossier`,
      anonymousCmr: 'UN CMR ANONYME DOIT ÊTRE CRÉÉ POUR CETTE COMMANDE.',
      phone: 'Téléphone',
      email: 'Email',
    },
    planning: {
      planning: 'Planification',
      licensePlate: 'Plaque d immatriculation',
      codeName: 'Nom de code',
    },
    orderConfirmation: {
      orderConfirmation: 'Confirmation de commande ',
      dearCustomer: 'Cher client, ',
      herebyWeConfirmYourOrder: 'Nous confirmons par la présente votre commande pour le transport suivant:',
      date: 'Date',
      ourReference: 'Notre référence',
      yourReference: 'Votre référence',
      load: 'Charger',
      unload: 'Décharger',
      reference: 'Référence',
      goods: 'Marchandises',
      description: 'Description',
      packageType: 'Type de palette',
      palletCount: 'Palettes',
      weight: 'Poids',
      LoadingMeter: 'Compteur de charge',
      freightRate: 'Tarif de fret',
      price: 'Prix',
      vat: 'TVA',
      questionsOrComments: 'Questions ou commentaires?',
      contactFileManager: `N'hésitez pas à contacter votre responsable de dossier`,
      phone: 'Téléphone',
      email: 'Email',
    },
    orderCancellation: {
      orderCancellation: 'Annulation de commande',
      dearCustomer: 'Cher client, ',
      orderCancelledText: 'Votre commande pour le transport ci-dessous a été annulée:',
      date: 'Date',
      ourReference: 'Notre référence',
      yourReference: 'Votre référence',
      load: 'Charger',
      unload: 'Décharger',
      reference: 'Référence',
      goods: 'Marchandises',
      description: 'Description',
      packageType: 'Type de palette',
      palletCount: 'Palettes',
      weight: 'Poids',
      LoadingMeter: 'Compteur de charge',
      freightRate: 'Tarif de fret',
      price: 'Prix',
      vat: 'TVA',
      questionsOrComments: 'Questions ou commentaires?',
      contactFileManager: `N'hésitez pas à contacter votre responsable de dossier`,
      phone: 'Téléphone',
      email: 'Email',
    },
    invoice: {
      invoice: 'Facture',
      customerNumber: 'Numéro de client',
      invoiceNumber: 'Numéro de facture',
      invoiceDate: 'Date de facture',
      dueDate: `Date d'échéance`,
      load: 'Chargement',
      unload: 'Déchargement',
      ourReference: 'Notre référence',
      yourReference: 'Votre référence',
      description: 'Description',
      quantity: 'Quantité',
      unitPrice: 'Prix unitaire',
      total: 'Total',
      vat: 'TVA',
      paymentInformation: 'Informations de paiement',
      amountToPay: 'Montant à payer',
      toBePaidBefore: 'À payer avant',
      beneficiary: 'Bénéficiaire',
      message: 'Message',
      generalTermsAndConditions: 'Conditions générales de vente jointes',
      questionsOrComments: `Pour toute question, veuillez contacter le service comptabilité à l'adresse {{email}}`,
    },
    sustainabilityReport: {
      sustainabilityReport: 'Rapport de durabilité',
      date: 'Date',
      ourReference: 'Notre référence',
      yourReference: 'Votre référence',
      truck: 'Truck',
      distance: 'Distance',
      weight: 'Poids',
      emissions: 'Émissions',
      questionsOrComments: 'Questions ou commentaires?',
      contactFileManager: `N'hésitez pas à contacter votre responsable de dossier`,
      phone: 'Téléphone',
      email: 'Email',
    },
    globalSustainabilityReport: {
      sustainabilityReport: 'Rapport de développement durable',
      date: 'Date',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      orderCount: 'Nombre de commandes',
      truck: 'Camion',
      totals: 'Total',
      distance: 'Distance',
      weight: 'Poids',
      emissions: 'Émissions de CO2',
      averagePerOrder: 'Moyenne par commande',
      framework:
        'Pour plus de détails sur le calcul des émissions, consultez la politique de développement durable sur notre site web.',
      questionsOrComments: 'Questions ou commentaires ?',
      contactFileManager: "N'hésitez pas à contacter votre gestionnaire de dossier",
      phone: 'Tél.',
      email: 'E-mail',
    },
    selfBill: {
      title: 'Facture auto-facturée',
      cashDiscount: 'Remise en espèces 2,5%',
      taxableAmount: 'Montant imposable',
      totalWithDiscount: 'Total avec escompte',
      totalWithoutDiscount: 'Total sans escompte',
      totalTitleWithDiscount: 'Paiement en espèces en 7 jours (2,5%)',
      totalTitleWithoutDiscount: 'Paiement sous 60 jours',
      or: 'OU',
    },
    creditNote: {
      creditNote: 'Note de crédit',
      customerNumber: 'Numéro de client',
      creditNoteNumber: 'Numéro de crédit',
      date: 'Date',
      dueDate: `Date d'échéance`,
      load: 'Chargement',
      unload: 'Déchargement',
      ourReference: 'Notre référence',
      yourReference: 'Votre référence',
      description: 'Description',
      quantity: 'Quantité',
      unitPrice: 'Prix unitaire',
      total: 'Total',
      vat: 'TVA',
      paymentInformation: 'Informations de paiement',
      amountToPay: 'Montant à payer',
      toBePaidBefore: 'À payer avant',
      payer: 'Payeur',
      message: 'Message',
      generalTermsAndConditions: 'Conditions générales de vente jointes',
      questionsOrComments: `Pour toute question, veuillez contacter le service comptabilité à l'adresse {{email}}`,
    },
    totals: {
      totalExclVat: 'Total hors TVA',
      totalVat: 'Total TVA',
      totalInclVat: 'Total TTC',
      vatReverseCharged: 'VAT Reverse Charge, article 44 VAT Directive',
    },
  },
  pages: {
    confirmOrderStates: {
      headingRefused: 'La commande a été refusée',
      headingCancelled: 'La commande a été annulée',
      headingConfirmed: 'La commande a été confirmée',
      headingUnknownStatus: 'La commande a déjà été exécutée ou son état est inconnu',
    },
    confirmOrderPlanningStates: {
      headingRefused: 'La planification a été refusée',
      headingCancelled: 'La planification a été annulée',
      headingConfirmed: 'La planification a été confirmée',
      headingUnknownStatus: 'La planification a déjà été exécutée ou son statut est inconnu',
    },
    confirmOrder: {
      heading: `Confirmer l'ordre de transport {{order.orderNumber}}`,
      orderConfirmed: 'Commande confirmée',
      orderConfirmationError: 'Impossible de confirmer la commande: {{error}}',
      confirmButton: `Confirmer l'ordre de transport`,
    },
    confirmPlanning: {
      heading: `Confirmer planning {{order.orderNumber}}`,
      planningConfirmed: 'Commande confirmée',
      confirmButton: `Confirmer l'ordre de transport`,
    },
    refuseOrder: {
      heading: `Refuser l'ordre de transport {{order.orderNumber}}`,
      orderRefused: 'Commande confirmée',
      orderRefusalError: 'Impossible de refuser la commande: {{error}}',
      refuseButton: `Refuser l'ordre de transport`,
    },
    refusePlanning: {
      heading: 'Refuser la planification {{planningDate}}',
      planningRefused: 'Planification refusée',
      refuseButton: `Refuser la planification`,
    },
    orderSummary: {
      loadAndUnloadInformation: 'Informations de chargement et de déchargement',
      load: 'Charger',
      unload: 'Décharger',
      freightRate: 'Tarif de fret',
      total: 'Total',
    },
  },
  customerPortal: {
    requestAccount: {
      title: 'Demander un compte',
      success: 'La demande a été envoyée, nous vous contacterons pour les étapes suivantes.',
      failed: 'Impossible de demander un compte: {{error}}',
      name: 'Nom',
      email: 'E-mail',
      companyName: "Nom de l'entreprise",
      companyNumber: "Numéro d'entreprise",
      request: 'Demander',
      dontHaveAnAccount: "Vous n'avez pas encore de compte?",
    },
    invite: {
      title: 'Invité au portail client',
      details:
        'Vous avez été invité au portail client de TransDirect, créez votre compte en entrant votre mot de passe.',
      accepted: "L'invitation a été acceptée, vous serez redirigé vers la page d'accueil sous peu.",
      acceptError: "Impossible d'accepter l'invitation: {{error}}",
      acceptButtonText: "Accepter l'invitation",
      password: 'Mot de passe',
      repeatPassword: 'Répétez le mot de passe',
    },
    home: {
      customerPortal: 'Portail client',
      title: 'Portail client de {{customerName}}',
      hello: 'Bonjour,',
      welcome: 'Bienvenue sur le portail client.',
      manageOrders: 'Gérez toutes vos commandes de transport.',
      viewInvoices: 'Consultez et payez vos factures facilement.',
      createQuotes: 'Créer et suivre des devis.',
      viewLocations: 'Voir tous les lieux de transport.',
    },
    locations: {
      locations: 'Lieux',
      name: 'Nom',
      street: 'Rue',
      number: 'Numéro',
      countryCode: 'Code pays',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      language: 'Langue',
      notes: 'Notes',
      createLocation: 'Créer un lieu',
      newLocation: 'Nouveau lieu',
      locationCreated: 'Lieu créé',
      couldNotCreateLocation: 'Impossible de créer le lieu : {{error}}',
      findLocation: 'Trouver un lieu...',
      address: 'Adresse',
      locationNotFound: 'Lieu non trouvé',
      couldNotLoadLocation: 'Impossible de charger le lieu : {{error}}',
    },
    order: {
      duplicate: 'Dupliquer',
      overview: 'Aperçu',
    },
    orders: {
      orders: 'Commandes',
      placeOrder: 'Passer une commande',
      invoiceRef: 'Référence de facture',
      orderRef: 'Référence de commande',
      loadLocation: 'Lieu de chargement',
      unloadLocation: 'Lieu de déchargement',
      loadDate: 'Date de chargement',
      loadTime: 'Heure de chargement',
      unloadDate: 'Date de déchargement',
      unloadTime: 'Heure de déchargement',
      invoiceTotal: 'Total de la facture',
      status: 'Statut',
      invoiceStatus: 'Statut de la facture',
      hasCMR: 'A un CMR',
      creationDate: 'Date de création',
      executionDate: "Date d'exécution",
      fileManager: 'Gestionnaire de fichiers',
      cancellationReason: "Motif d'annulation",
      stops: 'Arrêts',
      deleteStop: "Supprimer l'arrêt",
      purchases: 'Achats',
      total: 'Total',
      trailerTypes: 'Types de remorque',
      extraNotes: 'Notes supplémentaires',
      noContent: 'Pas de contenu',
      packaging: 'Emballage',
      coli: 'Coli',
      content: 'Contenu',
      addContent: 'Ajouter du contenu',
      deleteContent: 'Supprimer du contenu',
      noStops: "Pas d'arrêts",
      noStopsError: 'Ajoutez au moins un arrêt de chargement et de déchargement',
      reference: 'Référence',
      complaints: 'Réclamations',
      fileComplaint: 'Signaler une plainte',
      fileComplaintDescription: 'Remplissez ce formulaire pour signaler une plainte',
      send: 'Envoyer',
      reason: 'Raison',
      details: 'Détails',
      complaintFiled: 'Réclamation déposée',
      couldNotFileComplaint: 'Impossible de déposer une réclamation : {{error}}',
      placeAnOrder: 'Passer une commande',
      assignment: 'Tâche',
      addStop: 'Ajouter un arrêt',
      location: 'Lieu',
      date: 'Date',
      start: 'Début',
      stop: 'Fin',
      weight: 'Poids (kg)',
      volume: 'Volume (m3)',
      loadMeter: 'Mètre de charge',
      stopsRequired: 'Au moins 1 arrêt est requis',
      contentRequired: 'Au moins 1 élément de contenu est requis',
      truck: 'Truck',
      distance: 'Distance',
      emissions: 'Émissions',
      sustainability: 'Durabilité',
      attachments: 'Pièces jointes',
      uploadAttachments: 'Télécharger les pièces jointes',
      attachmentDeleted: 'Pièce jointe supprimée',
      couldNotDeleteAttachment: 'Impossible de supprimer la pièce jointe: {{error}}',
      uploadedAttachment: 'Pièce jointe {{document}} téléchargée',
      uploadFailed: 'Impossible de télécharger la pièce jointe {{document}}: {{error}}',
    },
    quotes: {
      quotes: 'Devis',
      quote: 'Devis',
      quoteReference: 'Référence du devis',
      quoteDate: 'Date du devis',
      expirationDate: "Date d'expiration",
      total: 'Total',
      search: 'Rechercher un devis...',
      quotation: 'Devis {{reference}}',
      notFound: 'Devis non trouvé',
      departurePlace: 'Lieu de départ',
      destination: 'Destination',
      distance: 'Distance',
      price: 'Prix',
      type: 'Type',
      requests: 'Demander des devis',
      request: 'Demander un devis',
      notes: 'Notes',
      lines: 'Lignes de devis',
      addLine: 'Ajouter une ligne',
      noLines: 'Aucune ligne de devis',
      requestTitle: 'Devis {{reference}}',
      requestSuccess: 'Devis demandé',
      requestFailed: 'Impossible de demander un devis : {{error}}',
      quotationRequests: 'Demandes de devis',
      requestedAt: 'Demandé à',
      hasQuote: 'A un devis',
      generalInformation: 'Informations générales',
      removeLine: 'Supprimer la ligne',
    },
    quoteLine: {
      from: 'De',
      to: 'À',
    },
    invoices: {
      invoices: 'Factures',
      invoiceNumber: 'Numéro de facture',
      invoiceDate: 'Date de facture',
      dueDate: "Date d'échéance",
      total: 'Total',
      search: 'Rechercher une facture...',
      notFound: 'Facture non trouvée',
      invoice: 'Facture {{invoiceNumber}}',
      structuredComment: 'Commentaire structuré',
      reference: 'Référence',
      sentAt: 'Envoyée le',
      load: 'Chargement',
      unload: 'Déchargement',
      description: 'Description',
      amount: 'Quantité',
      unitPrice: 'Prix unitaire',
      vat: 'TVA',
      extraInformation: 'Informations supplémentaires: {{extraInformation}}',
      orderTotal: 'Total de la commande: €{{total}}',
      totalExclVat: 'Total HT:',
      totalVat: 'Total TVA:',
      totalInclVat: 'Total TTC:',
      generalInformation: 'Informations générales',
    },
    creditNotes: {
      creditNotes: 'Notes de crédit',
      creditNoteNumber: 'Numéro de note de crédit',
      creditNoteDate: 'Date de note de crédit',
      dueDate: "Date d'échéance",
      total: 'Total',
      search: 'Rechercher une note de crédit...',
      notFound: 'Note de crédit non trouvée',
      creditNote: 'Note de crédit {{creditNoteNumber}}',
      structuredComment: 'Communication structurée',
      reference: 'Référence',
      sentAt: 'Envoyé le',
      load: 'Charger',
      unload: 'Décharger',
      description: 'Description',
      amount: 'Montant',
      unitPrice: 'Prix unitaire',
      vat: 'TVA',
      extraInformation: 'Informations supplémentaires: {{extraInformation}}',
      orderTotal: 'Total de la commande: €{{total}}',
      totalExclVat: 'Total hors TVA:',
      totalVat: 'Total TVA:',
      totalInclVat: 'Total TVA incluse:',
      generalInformation: 'Informations générales',
    },
    businessInformation: {
      businessInformation: "Informations sur l'entreprise",
      updateBusinessInformation: "Mettre à jour les informations de l'entreprise",
      name: 'Nom',
      vatNumber: 'Numéro de TVA',
      businessNumber: "Numéro d'entreprise",
      saveChanges: 'Sauvegarder les modifications',
      informationChanged: 'Les informations ont été mises à jour',
      informationNotChanged: 'Impossible de mettre à jour les informations : {{error}}',
      requestChanges: `Pour mettre à jour le nom de l'entreprise, le numéro de TVA ou le numéro d'entreprise, veuillez nous contacter.`,
    },
    ourInformation: {
      ourInformation: 'Nos Informations',
      name: 'Nom',
      vatNumber: 'Numéro de TVA',
      businessNumber: "Numéro d'entreprise",
      address: 'Adresse',
      contact: 'Contact',
      phoneNumber: 'Numéro de téléphone',
      emailTransport: 'Email transport',
      emailAccounting: 'Email comptabilité',
      emailGeneral: 'Email général',
    },
    contacts: {
      contacts: 'Contacts',
      name: 'Nom',
      email: 'E-mail',
      phone: 'Téléphone',
      function: 'Fonction',
      language: 'Langue',
      notifications: 'Notifications',
      newContact: 'Nouveau contact',
      contactCreated: 'Contact créé',
      couldNotCreateContact: 'Impossible de créer le contact: {{error}}',
      createContact: 'Créer un contact',
      notFound: 'Contact non trouvé',
      updatedContact: 'Contact mis à jour',
      couldNotUpdateContact: 'Impossible de mettre à jour le contact : {{error}}',
      updateContact: 'Mettre à jour le contact',
      delete: 'Supprimer',
      deleteContact: 'Supprimer le contact',
      deleteContactConfirmation: 'Êtes-vous sûr de vouloir supprimer le contact {{name}}?',
      deleteContactError: 'Impossible de supprimer le contact: {{error}}',
      deleteContactSuccess: 'Contact supprimé',
    },
    users: {
      users: 'Utilisateurs',
      inviteUser: 'Inviter un utilisateur',
      invitedUsers: 'Utilisateurs invités',
      noInvitedUsers: 'Aucun utilisateur invité',
      removeInvite: "Supprimer l'invitation",
      confirmRemoveInvite: "Êtes-vous sûr de vouloir supprimer l'invitation pour {{email}}?",
      inviteRemoved: 'Invitation supprimée',
      couldNotRemoveInvite: "Impossible de supprimer l'invitation : {{error}}",
      noUsersFound: 'Aucun utilisateur trouvé',
      removeUser: "Supprimer l'utilisateur",
      confirmRemoveUser: "Êtes-vous sûr de vouloir supprimer l'utilisateur {{name}}?",
      userRemoved: 'Utilisateur supprimé',
      couldNotRemoveUser: "Impossible de supprimer l'utilisateur : {{error}}",
      active: 'Actif',
      inactive: 'Inactif',
      userInvited: 'Utilisateur invité',
      couldNotInviteUser: "Impossible d'inviter l'utilisateur : {{error}}",
      name: 'Nom',
      email: 'E-Mail',
      isActive: 'Est actif?',
    },
    myProfile: {
      myProfile: 'Mon profil',
      logout: 'Déconnexion',
      name: 'Nom',
      role: 'Rôle',
      email: 'Email',
      language: 'Langue',
      languageChanged: 'Langue mise à jour',
      languageNotChanged: 'Impossible de mettre à jour la langue : {{error}}',
      joinedAt: 'Inscrit le',
      nameChanged: 'Le nom a été mis à jour',
      nameNotChanged: 'Impossible de mettre à jour le nom : {{error}}',
    },
    cmrReminder: {
      cmrReminder: 'Rappel CMR',
      sendReminder: 'Envoyer',
      confirmText: 'Êtes-vous sûr de vouloir envoyer un rappel CMR ?',
      confirmation: 'Rappel CMR envoyé',
      error: "Impossible d'envoyer le rappel CMR : {{error}}",
    },
    sustainability: {
      sustainability: 'Durabilité',
      policy: 'Politique de durabilité',
      viewOverview: 'Voir l’aperçu de la durabilité',
      orderCount: 'Nombre de commandes',
      totalDistance: 'Distance totale',
      totalWeight: 'Poids total',
      totalEmissions: 'Émissions totales de CO2',
      averageDistance: 'Distance moyenne',
      averageWeight: 'Poids moyen',
      averageEmissions: 'Émissions moyennes',
      total: 'Total',
      avgPerOrder: 'Moyenne par commande',
    },
    loading: 'Chargement...',
  },
  carrierPortal: {
    home: {
      carrierPortal: 'Carrierportaal',
      title: 'Carrierportaal',
      hello: 'Hallo,',
      welcome: 'Welkom bij het carrierportaal.',
      manageOrders: 'Beheer al je transport opdrachten.',
      managePlanning: 'Beheer je planning.',
      selfBilling: 'Maak en beheer je selfbilling facturen.',
    },
    orders: {
      orders: 'Orders',
    },
    planning: {
      planning: 'Planning',
    },
    selfBilling: {
      selfBilling: 'Self Billing',
    },
    businessInformation: {
      businessInformation: 'Bedrijfsgegevens',
    }
  },
  orderStatus: {
    Draft: 'Brouillon',
    Created: 'Créé',
    Placed: 'Placé',
    Confirmed: 'Confirmé',
    Refused: 'Refusé',
    Executed: 'Exécuté',
    Cancelled: 'Annulé',
  },
  orderSaleStatus: {
    Created: 'Créé',
    Credited: 'Crédité',
    Incomplete: 'Incomplet',
    Invoiced: 'Facturé',
  },
  customerContactNotifications: {
    invoice: 'Facturation',
    orderConfirmation: 'Confirmation de commande',
    cmr: 'CMR',
    quotation: 'Devis',
    dieselSurcharge: 'Supplément diesel',
    paymentReminder: 'Rappel de paiement',
  },
  errors: {
    required: 'Requis',
    invalidEmail: 'E-mail invalide',
    dateShouldBeInTheFuture: 'La date doit être dans le futur',
    startTimeShouldBeBeforeEndTime: "L'heure de début doit être antérieure à l'heure de fin",
    passwordsDontMatch: 'Le mot de passe et la confirmation ne correspondent pas.',
    passwordTooShort: 'Le mot de passe est trop court.',
  },
  orderComplaintType: {
    Aggression: 'Agression physique/verbale',
    DamagedGoods: 'Marchandises endommagées',
    IncorrectDelivery: 'Livraison incorrecte',
    IncorrectInvoice: 'Facture incorrecte',
    LateDelivery: 'Livraison tardive',
    Other: 'Autre',
  },
  auth: {
    forgotPassword: {
      title: 'Mot de passe oublié',
      requestNewPassword: 'Demander un nouveau mot de passe',
      instructions:
        'Entrez votre e-mail, si l’utilisateur existe dans le système, vous recevrez bientôt un e-mail pour définir un nouveau mot de passe.',
      requestFailed: 'Impossible de demander un nouveau mot de passe',
      instructionsSent:
        'Les instructions pour définir un nouveau mot de passe ont été envoyées à votre adresse e-mail.',
    },
    configNewPassword: {
      title: 'Définir un nouveau mot de passe',
      newPassword: 'Nouveau mot de passe',
      repeatNewPassword: 'Répétez le nouveau mot de passe',
      confirmButtonText: 'Définir le nouveau mot de passe',
      passwordDontMatch: 'Le mot de passe et la répétition du mot de passe doivent correspondre',
      tokenNotFound: 'Jeton non trouvé',
      invalidToken: 'Le jeton n’est plus valide',
      configFailed: 'Impossible de définir le mot de passe : {{error}}',
    },
    login: {
      login: 'Connexion',
      email: 'E-mail',
      password: 'Mot de passe',
      forgotPassword: 'Mot de passe oublié',
      customerLoginTitle: 'Connexion client',
      loginWithGoogle: 'Se connecter avec Google',
      incorrectCredentials: 'E-mail ou mot de passe incorrect',
      loginFailed: 'Impossible de se connecter : {{error}}',
    },
    userNotFound: {
      title: 'Utilisateur non trouvé',
      description: 'Impossible de se connecter, une erreur s’est produite ou l’utilisateur n’existe plus.',
      goToLoginPage: 'Aller à la page de connexion',
    },
  },
  upload: {
    title: 'Télécharger',
    uploadDocuments: 'Télécharger des documents',
    confirmDeleteDocument: 'Êtes-vous sûr de vouloir supprimer ce document?',
    deleteDocument: 'Supprimer le document',
    noDocumentsFound: 'Aucun document trouvé',
    noDocumentsSelected: 'Aucun document sélectionné',
    uploadFailed: 'Impossible de télécharger le document',
    uploadDocumentFailed: 'Impossible de télécharger le fichier {{filename}}: {{reason}}',
    multiUploadFailed: 'Impossible de télécharger les fichiers: {{reason}}',
  },
  or: 'ou',
  cancel: 'Annuler',
  close: 'Fermer',
  moreInfo: "Plus d'infos",
  previous: 'Précédent',
  next: 'Suivant',
  delete: 'Supprimer',
};
