import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { COUNTRY_VALUES } from '@utils/address';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import {
  Country,
  CustomerType,
  EmailType,
  InvoiceDeliveryMethod,
  InvoiceFrequency,
  Language,
  useEmailParamsQuery,
  useUpdateCustomerMutation,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatVatNumber } from '../../../../utils/vat-number';
import { CargoTypeComboboxField, cargoTypeToComboboxItem } from '../../../order/cargoType/CargoTypeComboboxField';
import { CUSTOMER_TYPE_VALUES, INVOICE_DELIVERY_METHOD_VALUES, INVOICE_FREQUENCY_VALUES } from '../../select-values';
import { useCustomer } from './customerContext';
import { AutocompletePostalcode } from '../../../location/components/AutocompletePostalcode';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../../order/vatRate/VatRateComboboxField';
import { TrailerTypes } from '../../../order/pages/order/TrailerTypes';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { Card } from 'components/Card';
import { TemplateTextAreaField } from 'components/textarea/TemplateTextAreaField';
import { UserComboboxField } from 'src/app/user/components/UserCombobox';
import { captureException } from '@sentry/react';

const updateCustomerSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  type: Yup.mixed().nullable().required('Vereist'),
  vatNumber: Yup.string().vatNumber().required('Vereist'),
  companyNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
  paymentTerm: Yup.string().required('Vereist'),
  invoiceFrequency: Yup.mixed().nullable().required('Vereist'),
  invoiceDeliveryMethod: Yup.mixed().nullable().required('Vereist'),
  defaultCargoType: Yup.mixed().nullable().required('Vereist'),
  autoInvoicing: Yup.boolean().required('Vereist'),
  hasSelfBilling: Yup.boolean().required('Vereist'),
  onlySendInvoiceAttachment: Yup.boolean().required('Vereist'),
  dealOwner: Yup.mixed().required('Vereist'),
});

export const UpdateCustomerForm = () => {
  const { customer } = useCustomer();
  const [, updateCustomerMutation] = useUpdateCustomerMutation();

  const [_emailParams] = useEmailParamsQuery({
    variables: {
      emailType: EmailType.Cmr,
    },
  });
  const emailParams = _emailParams.data?.emailParams ?? [];

  const initialValues = useMemo(() => {
    return {
      accountingId: customer.accountingId ?? '' + customer.id,
      name: customer.name,
      vatNumber: formatVatNumber(customer.vatNumber),
      companyNumber: customer.companyNumber,
      street: customer.street,
      streetNumber: customer.streetNumber ?? '',
      city: customer.city,
      postalCode: customer.postalCode,
      country: COUNTRY_VALUES.find((v) => v.key === customer.country)!,
      language: LANGUAGE_VALUES.find((v) => v.key === customer.language)!,
      paymentTerm: `${customer.paymentTerm}`,
      invoiceFrequency: INVOICE_FREQUENCY_VALUES.find((v) => v.key === customer.invoiceFrequency)!,
      invoiceDeliveryMethod: INVOICE_DELIVERY_METHOD_VALUES.find((v) => v.key === customer.invoiceDeliveryMethod)!,
      internalNotes: customer.internalNotes,
      externalNotes: customer.externalNotes,
      type: CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type),
      defaultCargoType: customer.defaultCargoType ? cargoTypeToComboboxItem(customer.defaultCargoType) : null,
      defaultVatRate: customer.defaultVatRate ? vatRateToComboboxItem(customer.defaultVatRate) : null,
      sendCMRWithInvoice: customer.sendCMRWithInvoice,
      defaultTrailerTypes: customer.defaultTrailerTypes,
      autoInvoicing: customer.autoInvoicing,
      dieselSurchargeEnabled: customer.dieselSurchargeEnabled,
      hasCustomDieselSurcharge: customer.hasCustomDieselSurcharge,
      cmrReminderOverwrite: customer.cmrReminderOverwrite?.toString(10) ?? '',
      dieselSurchargePercentage: formatNumber(customer.dieselSurchargePercentage, 2),
      hasSelfBilling: customer.hasSelfBilling,
      onlySendInvoiceAttachment: customer.onlySendInvoiceAttachment,
      cmrEmailSubjectOverwrite: customer.cmrEmailSubjectOverwrite,
      dealOwner: customer.dealOwner,
      autoProcessCmr: customer.autoProcessCmr,
    };
  }, [customer]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={updateCustomerSchema}
        onSubmit={async (newValues) => {
          try {
            const patch: Partial<typeof newValues> = diff(initialValues, newValues);
            const defaultCargoTypeId = patch.defaultCargoType?.key;
            delete patch.defaultCargoType;
            const defaultVatRateId = patch.defaultVatRate?.key;
            delete patch.defaultVatRate;
            const dealOwnerId = patch.dealOwner?.id;
            delete patch.dealOwner;
            const updateData = {
              ...patch,
              dealOwnerId: dealOwnerId ?? undefined,
              country: patch.country ? (patch.country.key as Country) : undefined,
              language: patch.language ? (patch.language.key as Language) : undefined,
              paymentTerm: patch.paymentTerm ? +patch.paymentTerm : undefined,
              invoiceFrequency: patch.invoiceFrequency ? (patch.invoiceFrequency.key as InvoiceFrequency) : undefined,
              invoiceDeliveryMethod: patch.invoiceDeliveryMethod
                ? (patch.invoiceDeliveryMethod.key as InvoiceDeliveryMethod)
                : undefined,
              type: patch.type ? (patch.type.key as CustomerType) : undefined,
              defaultCargoTypeId,
              defaultVatRateId,
              cmrReminderOverwrite: patch.cmrReminderOverwrite
                ? +patch.cmrReminderOverwrite
                : patch.cmrReminderOverwrite === undefined
                  ? undefined
                  : null,
              dieselSurchargePercentage: patch.dieselSurchargePercentage
                ? parseNumberInput(patch.dieselSurchargePercentage, 2)
                : undefined,
              onlySendInvoiceAttachment: patch.onlySendInvoiceAttachment,
            };

            if (!newValues.dieselSurchargeEnabled) {
              updateData.dieselSurchargePercentage = 0;
              updateData.hasCustomDieselSurcharge = false;
            }

            if (!newValues.hasCustomDieselSurcharge) {
              updateData.dieselSurchargePercentage = 0;
            }

            if (Object.values(updateData).length) {
              const result = await updateCustomerMutation({
                id: customer.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
            }
            toast.success('Klant aangepast');
          } catch (err: any) {
            captureException(err);
            toast.error('Kon klant niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="grid gap-4">
              <Card title="Contactgegevens">
                <InputField labelText="ID Boekhouding" type="text" name="accountingId" />
                <InputField labelText="Naam" type="text" name="name" />
                <UserComboboxField labelText="Dealeigenaar" name="dealOwner" />
                <SimpleSelectField labelText="Type klant" items={CUSTOMER_TYPE_VALUES} name="type" />
                <InputField labelText="BTW Nummer" type="text" name="vatNumber" />
                <InputField labelText="Ondernemingsnummer" type="text" name="companyNumber" />
                <div className="flex gap-4">
                  <div style={{ flex: 4 }}>
                    <InputField labelText="Straat" type="text" name="street" />
                  </div>
                  <div style={{ flex: 1 }}>
                    <InputField labelText="Nr" type="text" name="streetNumber" />
                  </div>
                </div>
                <AutocompletePostalcode />
                <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
              </Card>

              <Card title="Facturatie">
                <InputField labelText="Betaaltermijn" type="number" name="paymentTerm" />
                <SimpleSelectField
                  labelText="Factuur frequentie"
                  items={INVOICE_FREQUENCY_VALUES}
                  name="invoiceFrequency"
                />
                <SimpleSelectField
                  labelText="Factuur levering"
                  items={INVOICE_DELIVERY_METHOD_VALUES}
                  name="invoiceDeliveryMethod"
                />
                <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
                <TextAreaField labelText="Externe notities (voor vervoerder)" name="externalNotes" spellCheck={true} />

                <div className="grid grid-cols-2 gap-x-2">
                  <CheckboxField labelText="Automatische facturatie" name="autoInvoicing" />
                  <CheckboxField labelText="CMRs bij facturatie email" name="sendCMRWithInvoice" />
                  <CheckboxField labelText="Self billing" name="hasSelfBilling" />
                  <CheckboxField labelText="Geen extra bijlagen bij factuur e-mail" name="onlySendInvoiceAttachment" />
                </div>
              </Card>

              <Card title="CMR">
                <InputField
                  labelText="Dagen voor CMR Herinnering overwrite"
                  type="number"
                  name="cmrReminderOverwrite"
                  step="1"
                />
                <TemplateTextAreaField
                  labelText="CMR Email onderwerp"
                  name="cmrEmailSubjectOverwrite"
                  params={emailParams}
                  rows={1}
                />
                <CheckboxField
                  labelText="Automatische CMR verwerking"
                  name="autoProcessCmr"
                />
              </Card>

              <Card title="Diesel toeslag">
                <CheckboxField labelText="Gebruikt diesel toeslag" name="dieselSurchargeEnabled" />
                {values['dieselSurchargeEnabled'] && (
                  <div>
                    <CheckboxField
                      labelText="Eigen diesel toeslag"
                      name="hasCustomDieselSurcharge"
                      isDisabled={!values['dieselSurchargeEnabled']}
                    />
                    <InputField
                      labelText="Eigen diesel toeslag"
                      type="number"
                      name="dieselSurchargePercentage"
                      step="0.01"
                      isDisabled={!values['dieselSurchargeEnabled'] || !values['hasCustomDieselSurcharge']}
                    />
                  </div>
                )}
              </Card>

              <Card title="Standaard waarden">
                <CargoTypeComboboxField labelText="Standaard inhoud type" name="defaultCargoType" />
                <VatRateComboboxField labelText="Standaard BTW Tarief" name="defaultVatRate" />
              </Card>

              <Card title="Standaard Trailer types">
                <TrailerTypes
                  value={values.defaultTrailerTypes}
                  onChange={(newTrailerTypes) => {
                    setFieldValue('defaultTrailerTypes', newTrailerTypes);
                  }}
                  isMultiSelect={true}
                />
              </Card>

              <div>
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<Plus className="button-icon" />}
                >
                  Bewaar aanpassingen
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
