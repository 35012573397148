import { Check, Plus, X } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useClient } from 'urql';
import toast from 'react-hot-toast';

import { PageHeader } from 'components/PageHeader';
import { Pagination } from 'components/pagination/Pagination';
import { SearchType, SearchWithType } from 'components/search-with-type/SearchWithType';
import { ITableHeader, Table } from 'components/table/Table';
import {
  OrderStatus,
  OrderDocumentType,
  OrderLineStopType,
  GetCarrierPortalOrdersQuery,
  GetCarrierPortalOrdersQueryVariables,
  GetCarrierPortalOrdersDocument,
  CarrierPortalOrdersFilterInputData,
  useLinkOrderDocumentMutation,
  useUnlinkOrderDocumentMutation,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate, formatInputTime } from '../../../utils/date';
import { formatNumber } from '../../../utils/number';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { useTranslation } from '../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { FileUploaderButton } from 'src/app/document/components/FileUploaderButton';

export type CustomerOrder = GetCarrierPortalOrdersQuery['carrierPortalOrders'][0];

export const CarrierPortalOrdersPage = () => {
  const client = useClient();
  const { i18n } = useTranslation();

  const searchTypes: SearchType[] = [
    {
      label: i18n('customerPortal.orders.invoiceRef'),
      value: 'customerRef',
    },
    {
      label: i18n('customerPortal.orders.orderRef'),
      value: 'orderNumber',
    },
  ];

  const tableHeaders: ITableHeader[] = [
    {
      id: 'id',
      name: i18n('carrierPortal.orders.orderRef'),
    },
    {
      id: 'loadLocation',
      name: i18n('carrierPortal.orders.loadLocation'),
    },
    {
      id: 'unloadLocation',
      name: i18n('carrierPortal.orders.unloadLocation'),
    },
    {
      id: 'loadDate',
      name: i18n('carrierPortal.orders.loadLocation'),
    },
    {
      id: 'unloadDate',
      name: i18n('carrierPortal.orders.unloadLocation'),
    },
    {
      id: 'saleAmount',
      name: i18n('carrierPortal.orders.invoiceTotal'),
    },
    {
      id: 'cmr',
      name: i18n('carrierPortal.orders.hasCMR'),
    },
  ];

  // Filter values
  const [search, setSearch] = useState({
    value: '',
    type: searchTypes[0].value,
  });

  const [filters, setFilters] = useState<CarrierPortalOrdersFilterInputData>({});
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCarrierPortalOrdersQuery, GetCarrierPortalOrdersQueryVariables>(
          GetCarrierPortalOrdersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.carrierPortalOrders ?? [];
    },
    [client, filters],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const [, linkDocument] = useLinkOrderDocumentMutation();
  const [, unlinkDocument] = useUnlinkOrderDocumentMutation();

  useEffect(() => {
    page.reset();
  }, [filters]);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const filters: CarrierPortalOrdersFilterInputData = {};

      const setSearch = (value: string, type: string) => {
        switch (type) {
          case 'orderNumber':
            filters.orderNumber = value;
            break;
          case 'carrierRef':
            filters.carrierRef = value;
            break;
        }
      };

      if (search.value) {
        setSearch(search.value, search.type);
      }

      setFilters(filters);
    }, 100);
    return () => clearTimeout(timeoutRef);
  }, [search]);

  return (
    <>
      <PageHeader title={i18n('carrierPortal.orders.orders')} />

      <>
        <PageHeading
          leftSide={<Breadcrumb currentItem={i18n('carrierPortal.orders.orders')} />}
          
        />

        <div className="w-full px-4 pb-4">
          <SearchWithType
            value={search.value}
            onChange={(val) => setSearch((prev) => ({ ...prev, value: val }))}
            types={searchTypes}
            selectedType={search.type}
            onTypeChange={(val) => setSearch((prev) => ({ ...prev, type: val }))}
          />
        </div>

        <div className="w-full-content overflow-auto">
          <Table
            idKey="id"
            headers={tableHeaders}
            data={page.data}
            mapData={(order) => {
              console.log(order)
              const loadStops = order.lines.map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Load)).flat();
              const unloadStops = order.lines
                .map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Unload))
                .flat();
              const loadDates = Array.from(new Set(loadStops.map((s) => formatDate(s.date)))).join(', ');
              const unloadDates = Array.from(new Set(unloadStops.map((s) => formatDate(s.date)))).join(', ');
              const lineSales = order.lines.map((l) => l.purchases).flat();
              console.log(lineSales)
              const saleTotal = calculateLinesTotalExclVat(lineSales);


              return [
                <Link to={order.id} className="link-text whitespace-nowrap">
                  {order.orderNumber ?? 'DRAFT'}
                </Link>,
                [
                  ...new Set(
                    order.lines
                      .map((l) =>
                        l.stops
                          .filter((s) => s.type === OrderLineStopType.Load)
                          .map((s) => s.location.city)
                          .flat(),
                      )
                      .flat(),
                  ),
                ].join(', '),
                [
                  ...new Set(
                    order.lines
                      .map((l) =>
                        l.stops
                          .filter((s) => s.type === OrderLineStopType.Unload)
                          .map((s) => s.location.city)
                          .flat(),
                      )
                      .flat(),
                  ),
                ].join(', '),
                loadDates,
                unloadDates,
                <div className="whitespace-nowrap">{`€ ${formatNumber(saleTotal, 2, {
                  decimalSeperator: ',',
                })}`}</div>,
                <FileUploaderButton
                  buttonText="CMR"
                  title="CMR"
                  initialName={`CMR ${order.orderNumber ?? ''}`}
                  file={order.documents.find((d) => d.type === OrderDocumentType.Cmr)?.document}
                  onSubmit={async (document) => {
                  const result = await linkDocument({
                    orderId: order.id,
                    documentId: document.id,
                    type: OrderDocumentType.Cmr,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                }}
                unlinkDocument={async () => {
                  const document = order.documents.find((d) => d.type === OrderDocumentType.Cmr)?.document;
                  if (!document) {
                    return;
                  }

                  try {
                    const result = await unlinkDocument({
                      orderId: order.id,
                      documentId: document.id,
                      type: OrderDocumentType.Cmr,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Bestand gewist');
                  } catch (err) {
                    captureException(err);
                    toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                  }
                }}
                disableUpload={order.status === OrderStatus.Cancelled}
              />
                // order.documents.find((d) => d.type === OrderDocumentType.Cmr) ? (
                //   <Check className="w-6 h-6 text-feedback-positive" />
                // ) : (
                //   <X className="w-6 h-6 text-feedback-negative-04" />
                // ),
              ];
            }}
          />
        </div>

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>
      </>
    </>
  );
};
