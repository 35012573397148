import { useCreateInvoiceCtx } from './context';
import { nullthrows } from '../../../../../utils/invariant';
import { OrderSelect as OrderSelectComponent } from '../../../components/OrderSelect';

interface IOrderSelectProps {
  searchValue: string;
}

export const OrderSelect: React.FC<IOrderSelectProps> = (props) => {
  const { searchValue } = props;
  const { state: invoiceState, dispatch } = useCreateInvoiceCtx();
  const customer = nullthrows(invoiceState.selectedCustomer, 'No customer selected');

  const internalNotes = customer.customer.internalNotes.trim();

  return (
    <div>
      <div className="mb-4">
        <div className="font-medium">{`Geselecteerde klant: ${customer.customer.name} (${customer.customer.vatNumber})`}</div>
        <div className="grid grid-cols-2">
          <div>Factuur frequentie: {customer.customer.invoiceFrequency}</div>
          <div>CMR Bij Factuur: {customer.customer.sendCMRWithInvoice ? 'Ja' : 'Nee'}</div>
        </div>
        {internalNotes.length > 0 && (
          <div>
            <div>Interne notities:</div>
            <div>{customer.customer.internalNotes}</div>
          </div>
        )}
      </div>

      <OrderSelectComponent
        customerId={customer.customer.id}
        searchValue={searchValue}
        selectedOrderIds={invoiceState.orders.map((o) => o.id)}
        onSelectOrder={(o) => {
          dispatch({
            type: 'SELECT_ORDER',
            order: o,
          });
        }}
      />
    </div>
  );
};
