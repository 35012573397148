import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { Eye, EyeClosed } from '@phosphor-icons/react';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useMeQuery, usePasswordLoginMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { useTranslation } from '../../../contexts/translation-context';
import { GoogleAuthButton } from '../components/GoogleAuthButton';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Vereist'),
  password: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const PortalLoginPage = () => {
  const navigate = useNavigate();
  const [{ data }] = useMeQuery({
    requestPolicy: 'cache-and-network',
  });
  const [, passwordLogin] = usePasswordLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data?.me) {
      navigate('/customer-portal');
    }
  }, [data?.me]);

  // eslint-disable-next-line no-restricted-globals
  const redirectUri = window.location.origin;
  return (
    <>
      <PageHeader title={i18n('auth.login.customerLoginTitle')} />

      <main
        className="p-8 min-h-screen w-full"
        style={{
          background: "url('/static/graphic-elements/element-one.svg') no-repeat center right fixed",
        }}
      >
        <div className="flex flex-col justify-center h-full items-center mx-auto max-w-lg">
          <img style={{ width: '16rem' }} className="mt-16 mb-8" src="/static/logo-dark.svg" />

          <div className="w-full">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema}
              onSubmit={async (values) => {
                try {
                  const { email, password } = values;
                  const result = await passwordLogin({
                    email,
                    password,
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.passwordLogin;
                  if (!succeeded) {
                    throw new Error(i18n('auth.login.incorrectCredentials'));
                  }

                  // This should trigger the redirect to the correct page
                  // Logins should invalidate all mem-caches
                  // eslint-disable-next-line no-restricted-globals
                  window.location.reload();
                } catch (err: any) {
                  toast.error(i18n('auth.login.loginFailed', { error: getDisplayError(err) }));
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <InputField labelText={i18n('auth.login.email')} type="email" name="email" />
                  </div>

                  <div className="relative mb-6">
                    <div className="absolute flex text-sm underline text-dark-04 hover:text-orange-00 justify-end right-0 mt-1">
                      <Link to="/forgot-password">{i18n('auth.login.forgotPassword')}</Link>
                    </div>

                    <InputField
                      labelText={i18n('auth.login.password')}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                    />

                    {showPassword ? (
                      <EyeClosed
                        className="absolute text-dark-04 hover:text-dark-02 cursor-pointer w-6 h-6"
                        style={{ right: '.5rem', bottom: '2.1rem' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <Eye
                        className="absolute text-dark-04 hover:text-dark-02 cursor-pointer w-6 h-6"
                        style={{ right: '.5rem', bottom: '2.1rem' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>

                  <div>
                    <Button
                      type="submit"
                      color="default"
                      width="w-full"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {i18n('auth.login.login')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <div className="w-full flex items-center gap-4 my-8 uppercase">
            <div style={{ height: 1 }} className="w-full bg-dark-04 select-none">
              &nbsp;
            </div>
            <div className="text-dark-02">{i18n('or')}</div>
            <div style={{ height: 1 }} className="w-full bg-dark-04 select-none">
              &nbsp;
            </div>
          </div>

          <div className="w-full flex">
            <GoogleAuthButton redirectUri={redirectUri} />
          </div>

          <div className="mt-4 w-full text-dark-02">
            <span className="mr-1">{i18n('portal.requestAccount.dontHaveAnAccount')}</span>
            <Link to="/request-customer-portal-account" className="mt-2 block underline font-medium hover:text-orange-00">
              {i18n('portal.requestAccount.requestCustomerAccount')}
            </Link>
            <Link to="/request-carrier-portal-account" className="block  underline font-medium hover:text-orange-00">
              {i18n('portal.requestAccount.requestCarrierAccount')}
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};
